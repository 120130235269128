rodijco.controller 'CertificatesController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, $location, httpService) ->
    scope.selectedGroup = if routeParams.group then routeParams.group else 0
    scope.products = {}
    scope.savedProducts = {}

    scope.currentPage = if isNaN(routeParams.page) then 0 else parseInt(routeParams.page)
    scope.count = 0
    scope.limit = 10
    scope.totalPages = 0
    scope.showPages = 1
    scope.showHellip = false
    scope.selectedGroupData = {}
    scope.searchField = ''
    scope.showPagination = true
    scope.search = if routeParams.search then routeParams.search else ''

    setTimeout (->
      httpService.get 'certificates/groups', (response) ->
        scope.productGroups = response.data

        # firstGroup = scope.productGroups?[0]?._id
        # if firstGroup and not scope.selectedGroup
        #   $location.path('/certificates/' + firstGroup)

        for productGroup of scope.productGroups
          if scope.productGroups?[productGroup]?._id == scope.selectedGroup
            scope.selectedGroupData = scope.productGroups[productGroup]

        scope.searchField = '0'
    ), 5

    if not scope.search
      httpService.get "certificates?group=#{scope.selectedGroup}&page=#{scope.currentPage}", (response) ->
        if response.success
          scope.products = response.data?.products
          scope.savedProducts = response.data?.products
          scope.count = response.data?.count

          rounded = Math.ceil(scope.count / scope.limit)
          rounded = if rounded > 0 then rounded - 1 else rounded
          scope.totalPages = [0..(rounded)]

          scope.showHellip = if rounded > (rounded - scope.showPages) then true else false

          initTruncList()

    scope.$watch 'search', ->
      unless scope.search and scope.search.length > 1
        scope.products = scope.savedProducts
        scope.showPagination = true
        return

    scope.searchZ = () ->
      unless scope.search and scope.search.length > 1
        scope.products = scope.savedProducts
        scope.showPagination = true
        return

      httpService.get "certificates/search/#{scope.search}?group=#{scope.selectedGroup}&field=#{scope.searchField}&start=#{scope.currentPage}", (response) ->
        scope.showPagination = false
        scope.products = response.data?.rows if response.success

    if scope.search then scope.searchZ()

    scope.removeGroup = (group) ->
      httpService.delete "certificates/groups/#{group}", (response) ->
        if response.success
          Materialize.toast 'Certificaat groep verwijderd', DIALOG_DURATION
          $location.path '/certificates'
        else
          console.error response

    scope.removeProduct = (product) ->
      httpService.delete "certificates/#{product}", (response) ->
        if response.success
          location.reload()
        else
          console.error response

]
